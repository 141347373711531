import * as React from 'react';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import './player.css';
import PlayerDisplay from './playerDisplay';
import { PlayerInfo } from '../utils/playerInfo';
import useWindowDimensions from '../utils/windowDimensions';

const socketUrl = `${process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players`.replace('http', 'ws');

const socket = io(socketUrl);

function Player() {
    const [playerInfo, setPlayerInfo] = useState<PlayerInfo>({
        name: '',
        id: '',
        wins: 0,
        losses: 0,
        rating: 0,
        isGrandMaster: false
    });
    const location = useLocation();
    const [searchParams, ] = useSearchParams();
    const playerCode = location.pathname.replace("/player/", '').replace('-', '#').toUpperCase();
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    const isOverlay = searchParams.get('overlay') === "true"
    const showCode = searchParams.get('showCode') === "true"
    const showName = searchParams.get('showName') === "true"
    const transparent = searchParams.get('transparent') === "true"
      
    const updatePlayerInfo = (forceUpdate: boolean = true) => {
        socket.emit('updates', { code: playerCode, update: forceUpdate });
    }

    useEffect(() => {
        socket.on('connect', () => {
            updatePlayerInfo(false);
        })

        socket.on('disconnect', () => {
        });

        socket.on(playerCode, (p) => {
            setPlayerInfo(p);
        })

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off(playerCode);
        }
    })

    return (
        <div className="playerBox" style={{height: windowHeight, width: windowWidth, backgroundColor: (isOverlay && transparent) ? 'transparent' : ''}}>
            <PlayerDisplay
              player={playerInfo}
              height={windowHeight}
              width={windowWidth}
              isOverlay={isOverlay}
              showCode={showCode}
              showName={showName}
              playerCode={playerCode}
              transparent={transparent}
            ></PlayerDisplay>
            <div hidden={isOverlay}>
                <Button
                    variant='contained'
                    onClick={() => updatePlayerInfo(true)}
                >
                    update
                </Button>
                <p>
                    Use the following as a browser source:
                </p>
                <p>
                    <code>{window.location.href.replace(location.search, '')}?overlay=true&showCode=true&showName=true&transparent=false</code>
                </p>
                <p>
                  You can change 'showName' or 'showCode' to false to do what that sounds like. 'transparent' will make it have no black background
                </p>
                <p>
                    Pressing update here updates the overlay in OBS with an animation showing change in points, w/l, and rank
                </p>
                <p>
                    Add this page to OBS from 'Docks -&gt; Custom Browser Docks' to have the update button in OBS
                </p>
                <p>
                  <code>{window.location.href.replace(location.search, '')}</code>
                </p>
                <br />
                <h4>Stream Deck</h4>
                <p>
                  If you have a Stream Deck you can create a refresh button on that via the <a href="https://marketplace.elgato.com/product/api-request-dc7f3a02-e32c-4daf-b5a9-48c618deb6d1">API Request Plugin</a> or the <a href="https://marketplace.elgato.com/product/api-ninja-fd59edeb-e7e5-412f-91ef-304c3e03f035">API Ninja Plugin</a>
                </p>
                <p>
                  With either plugin simply create a button with the "Request type" (api ninja) or "HTTP Method" (api request) set to "GET" and the URL set to:
                </p>
                <p>
                 {process.env.REACT_APP_API_URL_OVERRIDE || process.env.REACT_APP_API_URL}/players/rankedInfo?code={playerCode.replace('#', '-').toLowerCase()}
                </p>
            </div>
        </div>
    )
}

export default Player
